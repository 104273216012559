/**
 * Stores mapping between a page's key and it's name, icon, component and path
 * @module pagesConfig
 * @summary Page configurations
 */

import * as pages from './asyncPages.js'
import categories from './drawerCategories.js'
import DashboardIcon from '@material-ui/icons/Dashboard'
import MapIcon from '@material-ui/icons/Map'
import ReportIcon from '@material-ui/icons/MultilineChart'
import EventsIcon from '@material-ui/icons/ScatterPlot'
import VehicleIcon from '@material-ui/icons/AirportShuttle'
import ImmobilizeIcon from '@material-ui/icons/Block'
import DriverIcon from '@material-ui/icons/AirlineSeatReclineNormal'
import UsersIcon from '@material-ui/icons/People'
import AccountIcon from '@material-ui/icons/AccountBox'
import BiometricIcon from '@material-ui/icons/Fingerprint'
import RoutesIcon from '@material-ui/icons/Directions'
import TripsIcon from '@material-ui/icons/SwapCalls'
import ActivityIcon from '@material-ui/icons/NearMe'
import FuelIcon from '@material-ui/icons/LocalGasStation'
import OBDDashboardIcon from '@material-ui/icons/SettingsInputSvideo'
import AOIIcon from '@material-ui/icons/SelectAll'
import Videocam from '@material-ui/icons/Videocam'
import SummaryIcon from '@material-ui/icons/Assessment'
import SchoolIcon from '@material-ui/icons/AccountBalance'
import FuelSummaryIcon from '@material-ui/icons/TableChart'

export default {
  ANALYTICS_DASHBOARD: {
    name: 'Analytics',
    icon: MapIcon,
    path: '/home/analytics',
    category: categories.HOME,
    order: 2,
    component: pages.AsyncAnalyticsDashboard,
  },

  ANALYTICS_DASHBOARD_ADDITIONAL_GRAPHS: {
    name: 'RDWSD Analytics',
    icon: MapIcon,
    path: '/home/rdwsd-analytics',
    category: categories.HOME,
    order: 2,
    component: pages.AsyncAnalyticsDashboardAdditionalGraphs,
  },

  ANALYTICS_2: {
    name: 'Analytics Dashboard',
    icon: MapIcon,
    path: '/home/analytics-new',
    category: categories.HOME,
    order: 2,
    component: pages.AsyncAnalyticsNewDashboard,
  },

  SCHOOL_DASHBOARD: {
    name: 'Analytics',
    icon: MapIcon,
    path: '/home/analytics',
    category: categories.HOME,
    order: 2,
    component: pages.AsyncSchoolDashboard,
  },

  GOOGLE_MAPS: {
    name: 'Dashboard',
    icon: DashboardIcon,
    path: '/home/dashboard',
    category: categories.HOME,
    order: 1,
    component: pages.AsyncGoogleMapsDashboard,
  },

  TRACTOR_STATUS: {
    name: 'Tractor Status',
    icon: DashboardIcon,
    path: '/home/dashboard',
    category: categories.HOME,
    order: 1,
    component: pages.AsyncTractorStatusDashboard,
  },

  RDWSD_DASHBOARD: {
    name: 'RDWSD Dashboard',
    icon: DashboardIcon,
    path: '/home/dashboard',
    category: categories.HOME,
    order: 1,
    component: pages.AsyncRdwsdDashboard,
  },

  OPENSTREET_MAPS: {
    name: 'Dashboard',
    icon: DashboardIcon,
    path: '/home/dashboard',
    category: categories.HOME,
    order: 1,
    component: pages.AsyncOSMapsDashboard,
  },

  REPORT: {
    name: 'Reports',
    icon: ReportIcon,
    path: '/home/report',
    category: categories.HOME,
    order: 3,
    component: pages.AsyncReport,
  },

  RDWSD_REPORT: {
    name: 'RDWSD Reports',
    icon: ReportIcon,
    path: '/home/report',
    category: categories.HOME,
    order: 3,
    component: pages.AsyncAdditionalReport,
  },

  IMMOBILIZE: {
    name: 'Mobilize/Immobilize',
    icon: ImmobilizeIcon,
    path: '/home/mobilize-immobilize',
    category: categories.ACTIONS,
    order: 1,
    component: pages.AsyncImmobilize,
  },

  ALERTS_CONFIG: {
    name: 'Alerts',
    icon: EventsIcon,
    path: '/home/alerts',
    category: categories.ACTIONS,
    order: 2,
    component: pages.AsyncAlertsDashboard,
  },

  AOI: {
    name: 'AOI',
    icon: AOIIcon,
    path: '/home/AOI',
    category: categories.GEO,
    order: 1,
    component: pages.AsyncAOI,
  },

  SCHOOL_AOI: {
    name: 'Stops',
    icon: AOIIcon,
    path: '/home/AOI',
    category: categories.GEO,
    order: 1,
    component: pages.AsyncSchoolAOI,
  },

  ROUTES: {
    name: 'Routes',
    icon: RoutesIcon,
    path: '/home/routes',
    category: categories.GEO,
    order: 2,
    component: pages.AsyncRoutes,
  },

  SCHOOL_ROUTES: {
    name: 'Auto-Routes',
    icon: RoutesIcon,
    path: '/home/auto-routes',
    category: categories.INTEGRATIONS,
    order: 2,
    component: pages.AsyncSchoolRoutes,
  },

  TRIPS: {
    name: 'Trips',
    icon: TripsIcon,
    path: '/home/trips',
    category: categories.GEO,
    order: 3,
    component: pages.AsyncTrips,
  },

  SCHOOL_TRIPS: {
    name: 'Auto-Trips',
    icon: TripsIcon,
    path: '/home/auto-trips',
    category: categories.INTEGRATIONS,
    order: 3,
    component: pages.AsyncSchoolTrip,
  },

  ACTIVITY: {
    name: 'Activity',
    icon: ActivityIcon,
    path: '/home/activity',
    category: categories.GEO,
    order: 4,
    component: pages.AsyncActivity,
  },

  TEST_RIDES: {
    name: 'Test Rides',
    icon: ActivityIcon,
    path: '/home/test-rides',
    category: categories.GEO,
    order: 4,
    component: pages.AsyncTestRides,
  },

  FUEL_DASHBOARD: {
    name: 'Fuel Dashboard',
    icon: FuelIcon,
    path: '/home/fuel-dashboard',
    category: categories.INTEGRATIONS,
    order: 1,
    component: pages.AsyncFuelDashboard,
  },

  FUEL_SUMMARY_DASHBOARD: {
    name: 'Fuel Summary',
    icon: FuelSummaryIcon,
    path: '/home/fuel-summary-dashboard',
    category: categories.INTEGRATIONS,
    order: 1,
    component: pages.AsyncFuelSummaryDashboard,
  },

  TANKER_DASHBOARD: {
    name: 'Fuel Tanker Dashboard',
    icon: FuelIcon,
    path: '/home/fuel-tanker-dashboard',
    category: categories.INTEGRATIONS,
    order: 2,
    component: pages.AsyncFuelTankerDashboard,
  },

  OBD_DASHBOARD: {
    name: 'OBD Dashboard',
    icon: OBDDashboardIcon,
    path: '/home/obd-dashboard',
    category: categories.INTEGRATIONS,
    order: 3,
    component: pages.AsyncOBD,
  },

  SCHOOL: {
    name: 'Schools',
    icon: SchoolIcon,
    path: '/home/manage-school',
    category: categories.MANAGEMENT,
    order: 1,
    component: pages.AsyncSchool,
  },

  SCHOOL_STUDENTS: {
    name: 'Students',
    icon: UsersIcon,
    path: '/home/manage-students',
    category: categories.MANAGEMENT,
    order: 2,
    component: pages.AsyncSchoolStudents,
  },

  MANAGE_VEHICLES: {
    name: 'Vehicles',
    icon: VehicleIcon,
    path: '/home/manage-vehicles',
    category: categories.MANAGEMENT,
    order: 3,
    component: pages.AsyncVehicles,
  },
  HEALTH_REPORT: {
    name: 'HEALTH Dashboard',
    icon: OBDDashboardIcon,
    path: '/home/manage-healthReport',
    category: categories.INTEGRATIONS,
    order: 4,
    component: pages.AsyncHealthReport,
  },
  

  MANAGE_DRIVERS: {
    name: 'Drivers',
    icon: DriverIcon,
    path: '/home/manage-drivers',
    category: categories.MANAGEMENT,
    order: 4,
    component: pages.AsyncDrivers,
  },

  MANAGE_DRIVERS_BIOMETRIC: {
    name: 'Biometric',
    icon: BiometricIcon,
    path: '/home/manage-biometric',
    category: categories.MANAGEMENT,
    order: 5,
    component: pages.AsyncDriversBiometric,
  },

  VEHICLES_RTO: {
    name: 'Vehicle Activation Status',
    icon: DriverIcon,
    path: '/home/manage-vehicle-registration',
    category: categories.MANAGEMENT,
    order: 4,
    component: pages.VehicleRegistrataionStatus,
  },

  MANAGE_USERS: {
    name: 'Users',
    icon: UsersIcon,
    path: '/home/manage-users',
    category: categories.MANAGEMENT,
    order: 5,
    component: pages.AsyncUsers,
  },

  MANAGE_ACCOUNT: {
    name: 'Account',
    icon: AccountIcon,
    path: '/home/account',
    category: categories.MANAGEMENT,
    order: 6,
    component: pages.AsyncAccount,
  },

  MANAGE_ACCOUNT_DETAILS: {
    name: 'Account Details',
    icon: AccountIcon,
    path: '/home/account-details',
    category: categories.MANAGEMENT,
    order: 7,
    component: pages.AsyncAccountDetails,
  },

  SUMMARY_ELECTRIC: {
    name: 'Summary',
    icon: SummaryIcon,
    path: '/home/summary-electric',
    category: categories.INTEGRATIONS,
    order: 1,
    component: pages.AsyncElectricSummary,
  },

  LIVE_VIDEO: {
    name: 'Live Video',
    icon: Videocam,
    path: '/home/live-video',
    category: categories.INTEGRATIONS,
    order: 4,
    component: pages.AsyncVideoStream,
  },

  REIL_VIDEO: {
    name: 'Live Video',
    icon: Videocam,
    path: '/home/live-video/multi-cam',
    category: categories.INTEGRATIONS,
    order: 4,
    component: pages.AsyncReilVideo,
  },

  CONTINENTAL_DASHBOARD: {
    name: 'TPMS',
    icon: MapIcon,
    path: '/home/tpms',
    category: categories.INTEGRATIONS,
    order: 4,
    component: pages.AsyncContinental,
    // component: pages.AsyncRawDataFiles
  },

  KM_REPORT: {
    name: 'KM Report',
    icon: Videocam,
    path: '/home/kmreport',
    category: categories.INTEGRATIONS,
    order: 4,
    component: pages.KmReport,
  },

  REPORT_PREPROC: {
    name: 'New Reports',
    icon: ReportIcon,
    path: '/home/new-report',
    category: categories.HOME,
    order: 3,
    component: pages.NewReports,
  },

  TRIP_CALENDER: {
    name: 'Trip Schedule',
    icon: TripsIcon,
    path: '/home/TripSchedule',
    category: categories.GEO,
    order: 3,
    component: pages.TripSchedule,
  },
  TRIPS_NEW: {
    name: 'New Trips',
    icon: TripsIcon,
    path: '/home/newtrips',
    category: categories.GEO,
    order: 3,
    component: pages.TripsNew,
  },
}
